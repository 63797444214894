import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import * as parse5 from 'parse5'
import * as esprima from 'esprima'

interface FilmState {
  data: any;
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any;
  isLoading: boolean;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
  isLoading: false
} as FilmState

const makeEsprimaObject = tree => {
  let ret = undefined
  switch (tree.type) {
    case 'Literal':
      ret = tree.value
      break
    case 'ArrayExpression':
      ret = tree.elements.map(makeEsprimaObject)
      break
    case 'ObjectExpression':
      ret = {}
      for (const prop of tree.properties) {
        ret[prop.key.name || prop.key.value] = makeEsprimaObject(prop.value)
      }
      break
  }

  return ret
}

const getSource = async (id, type = 'movie', dataName = 'mk') => {
  const movie: any = await axios.get(`https://api.kinogram.best/embed/${ type }/${ id }`)
  const dataHtml = parse5.parseFragment(movie.data)
  const scriptList: any = _.chain(dataHtml.childNodes).filter({nodeName: 'script'}).find((script: any) => !!_.find(script.attrs, {value: dataName})).value()
  const syntax = esprima.parseModule(scriptList.childNodes[0].value.split(';\n')[type === 'movie' ? 0 : 1].replace(/makePlayer/, '')) as any
  return makeEsprimaObject(syntax.body[0].expression)
}

export const handleFilmFetch = createAsyncThunk(
  'film/fetch',
  async (params) => {
    try {
      const response = await axios.get('https://api.zombie-film.live/v2/franchise/view/', {
        params: {
          slug: params.slug,
          findBy: 'init',
          ref: 'localhost',
          _format: 'json',
          all: true,
          season: params.season
        }
      })

      let id = response.data?.view?.franchiseId

      if (response.data?.view.video?.embedUrl) {
        id = _.last(response.data?.view?.video.embedUrl.split('/'))
        id = _.first(id.split('?'))
      }

      if (response.data?.view.trailer?.embedUrl) {
        id = _.last(response.data?.view?.trailer.embedUrl.split('/'))
        id = _.first(id.split('?'))
      }

      return {
        ...response.data,
        movie: await getSource(id, 'movie', 'mk'),
        trailer: response.data?.view?.trailer ? await getSource(id, 'trailer', 'trailer') : {}
      } as any
    } catch (e) {
      console.log('e', e)
     throw e
    }
  }
)

const filmSlice = createSlice<FilmState, any>({
  name: 'film',
  initialState,
  reducers: {
    resetFilm: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(handleFilmFetch.pending, (state) => {
      state.status = 'pending'
      state.data = {}
      state.isLoading = true
    })
    builder.addCase(handleFilmFetch.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(handleFilmFetch.rejected, (state, action: any) => {
      state.status = 'failed'
      state.data = {}
      state.error = action.error
      state.isLoading = false
    })
  }
})


export const {resetFilm} = filmSlice.actions

export default filmSlice.reducer
